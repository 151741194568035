<template>
  <VueQrcode :value="value" :options="{ width: 1000, tag: 'img' }" class="qr-code"></VueQrcode>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  components: {
    VueQrcode,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
canvas.qr-code {
  width: 100% !important;
  height: auto !important;
}
</style>
