






























// @ is an alias to /src
import Modal from '@/components/modal.vue';
import QRCode from '@/components/qrcode.vue';
import router from '@/router';
import { defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Modal,
    QRCode,
  },
  data() {
    return {
      modal: {
        isActive: true,
        isCard: true,
        close: () => {
          console.log();
        },
      },
      event: 0,
      exhibitor: 0,
    };
  },
  setup() {
    const { translations } = useContext();
    return { translations };
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.modal.close = this.closeQRCode;
  },
  methods: {
    closeQRCode() {
      router.push(`/event/${this.event}/manage-exhibitor/${this.exhibitor}/profile`);
    },

    downloadCode() {
      const link = document.createElement('a');
      link.download = 'qrcode.png';
      const canvas = document.querySelector('canvas.qr-code') as HTMLCanvasElement;
      if (canvas) {
        const url = canvas.toDataURL();
        link.href = url;
        link.click();
      }
    },
  },
});
